.Wrap {
  min-height: 100px;

  padding: 20px;

  background-color: black;

  color: #fff;

  text-align: center;
}

.LangSelect {
  text-align: center;
}

.LangItem {
  height: 32px;

  margin: auto 20px;

  cursor: pointer;

  transition: 0.1s;
}

.LangItem:hover {
  transform: scale(0.95);
}

.Wrap p {
  margin-top: 50px;
}

.Wrap a {
  margin-left: 10px;
}
