.Wrap {
  text-align: center;
}

.Title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}

.Quote {
  font-size: 18px;
}
