.Wrap {
  margin-top: 100px;
  text-align: center;
}

.Title {
  margin-bottom: 50px;
  font-size: 25px;
}

.Button {
  width: 200px;
  margin-left: auto;
  margin-right: auto;

  text-align: center;
  display: block;
  padding: 10px 30px;
  background-color: #011b72;
  border-radius: 100px;
  text-decoration: none;
  color: #fff;

  transition: 0.1s;
}

.Button:hover {
  transform: scale(0.95);
}
