* {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ContentBody_Wrap__3DBZU {
  max-width: 1300px;
  margin: 0px auto;

  -webkit-animation: ContentBody_MoveIn__23V1P 0.25s forwards;

          animation: ContentBody_MoveIn__23V1P 0.25s forwards;

  min-height: 100vh;

  padding: 50px 16px;
}

@-webkit-keyframes ContentBody_MoveIn__23V1P {
  0% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes ContentBody_MoveIn__23V1P {
  0% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
  }
}

.MoreGames_Wrap__s5CCa {
  margin-top: 100px;
  text-align: center;
}

.MoreGames_Title__1JttI {
  margin-bottom: 50px;
  font-size: 25px;
}

.MoreGames_Button__bWDpI {
  width: 200px;
  margin-left: auto;
  margin-right: auto;

  text-align: center;
  display: block;
  padding: 10px 30px;
  background-color: #011b72;
  border-radius: 100px;
  text-decoration: none;
  color: #fff;

  -webkit-transition: 0.1s;

  transition: 0.1s;
}

.MoreGames_Button__bWDpI:hover {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.CustomerRatings_Wrap__21iIW {
  text-align: center;
}

.CustomerRatings_Title__1XJyf {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}

.CustomerRatings_Quote__2i4h2 {
  font-size: 18px;
}

.ProductArticle_Wrap__24GZF {
}

.ProductArticle_Head__1YuZ0 {
  display: -webkit-flex;
  display: flex;
}

.ProductArticle_Title__bfyJb {
  display: -webkit-flex;
  display: flex;
  font-size: 30px;
}

.ProductArticle_Title__bfyJb > img {
  height: 50px;
  margin-right: 20px;
  margin-top: auto;
  margin-bottom: auto;
}

.ProductArticle_LangSelect__2PPfq {
  display: none;
  margin-right: 0px;
  margin-left: auto;

  display: -webkit-flex;

  display: flex;
}

.ProductArticle_LangSelect__2PPfq > img {
  display: none;
  height: 32px;

  margin: auto 0px;
  margin-left: 18px;

  cursor: pointer;

  -webkit-transition: 0.1s;

  transition: 0.1s;
}

.ProductArticle_LangSelect__2PPfq > img:hover {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.ProductArticle_LangSelect__2PPfq > img:first-child {
  margin-left: 0px;
}

.ProductArticle_Body__2n4Fr {
  margin-top: 30px;
  margin-bottom: 30px;

  font-size: 18px;
  white-space: pre-wrap;
}

.ProductArticle_Foot__aw47h {
  display: -webkit-flex;
  display: flex;
}

.ProductArticle_MediaCarousel__3_XvB {
  height: 100px;

  -webkit-flex: 1 1;

          flex: 1 1;
  overflow-x: auto;
}

.ProductArticle_MediaCarouselContainer__2JD1F {
  height: 100%;
  display: -webkit-flex;
  display: flex;
}

.ProductArticle_MediaCarouselContainer__2JD1F > img {
  height: 100%;
  margin-right: 10px;
  cursor: pointer;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}

.ProductArticle_MediaCarouselContainer__2JD1F > img:hover {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.ProductArticle_MediaCarouselContainer__2JD1F > img:last-child {
  margin-right: 0px;
}

.ProductArticle_Redirect__oqpc7 {
  margin: auto 0px;
  margin-left: 50px;
}

.ProductArticle_Price__2oloK {
  text-align: center;
  font-size: 16px;
  margin-bottom: 8px;
}

.ProductArticle_Button__2EH1Y {
  text-align: center;
  display: block;
  padding: 10px 30px;
  background-color: #e91e63;
  border-radius: 100px;
  text-decoration: none;
  color: #fff;

  -webkit-transition: 0.1s;

  transition: 0.1s;
}

.ProductArticle_Button__2EH1Y:hover {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.ProductArticle_ArticleDivider__1IUwh {
  width: 100%;
  height: 2px;

  margin: 100px 0px;

  background-color: #e8e8e8;
}

@media only screen and (max-width: 600px) {
  .ProductArticle_Head__1YuZ0 {
    display: inherit;
  }
  .ProductArticle_Foot__aw47h {
    display: inherit;
  }
  .ProductArticle_Redirect__oqpc7 {
    margin-top: 20px;
    margin-right: 50px;
  }
}

.NewProductItem_Wrap__3Ls64 {
  background-color: #011b72;
  min-height: 100vh;
  text-align: center;
}

.NewProductItem_Head__3T-pi {
  padding: 50px 0px;
  text-align: center;
  color: #fff;
  font-size: 30px;
}

.NewProductItem_TextNew__2C8lE {
  font-weight: 500;
}

.NewProductItem_SubHead__1_sxX {
  display: -webkit-inline-flex;
  display: inline-flex;
}

.NewProductItem_Symbol__1n7be {
  height: 50px;
  margin-right: 20px;
  margin-top: auto;
  margin-bottom: auto;
}

.NewProductItem_TextName__qEcnG {
  text-align: left;
}

.NewProductItem_Video__27b6B {
  display: inline-block;
  width: 100%;
  max-width: 800px;

  background-color: #000;
}

.NewProductItem_ButtonWrap__2cTD2 {
  display: -webkit-flex;
  display: flex;
  text-align: center;
}

.NewProductItem_Button__3rAls {
  margin: 50px auto;
  text-align: center;
  padding: 10px 30px;
  background-color: #fff;
  border-radius: 100px;
  text-decoration: none;
  color: #011b72;

  min-width: 200px;

  font-weight: 600;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}

.NewProductItem_Button__3rAls:hover {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.SocialLink_Wrap__fGgBv {
  text-align: center;
}

.SocialLink_Title__230RV {
  margin-bottom: 50px;
  font-size: 25px;
}

.SocialLink_ButtonGroup__DsSe0 {
  display: -webkit-inline-flex;
  display: inline-flex;
  text-align: center;
}

.SocialLink_Item__3Hg8X {
  margin: 0px 16px;
}

.SocialLink_Button__1pllQ {
  display: inline-block;
  text-align: center;
  padding: 10px 30px;
  background-color: #ff0000;
  border-radius: 100px;
  text-decoration: none;
  color: #fff;

  -webkit-transition: 0.1s;

  transition: 0.1s;
}

.SocialLink_Button__1pllQ:hover {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

@media only screen and (max-width: 700px) {
  .SocialLink_ButtonGroup__DsSe0 {
    display: inline;
    display: initial;
    text-align: center;
  }

  .SocialLink_Item__3Hg8X {
    margin: 10px 16px;
  }
}

.Header_Wrap__1YxbH,
.Header_WrapExpanded__2g_Es {
  height: 75px;

  color: #fff;
  background-color: #011b72;

  -webkit-transition: 0.5s;

  transition: 0.5s;

  overflow: hidden;
}

.Header_WrapExpanded__2g_Es {
  height: 100vh;
}

.Header_ControlWrap__3yMrZ {
  height: 75px;
  max-width: 1300px;
  margin: 0px auto;
  display: -webkit-flex;
  display: flex;
}

.Header_Left__1PPcD {
  display: -webkit-flex;
  display: flex;
}

.Header_Left__1PPcD * {
  height: 22px;

  margin-top: auto;
  margin-bottom: auto;
}

.Header_MobileMenuButton__Uzygi {
  display: none;
}

.Header_MobileMenuButton__Uzygi img {
  height: 22px;
}

.Header_Right__W0Cje {
  margin-right: 0px;
  margin-left: auto;
  display: -webkit-flex;
  display: flex;
}

.Header_Right__W0Cje > * {
  margin-left: 50px;
  margin-top: auto;
  margin-bottom: auto;

  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

.Header_NavButton__3378V {
  cursor: pointer;

  height: 30px;
  min-width: 150px;

  box-sizing: border-box;

  border-color: #fff;
  border-style: solid;
  border-width: 2px;
  border-radius: 30px;
  text-align: center;

  line-height: 150%;
  font-weight: 500;

  text-transform: uppercase;

  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */

  -webkit-transition: 0.1s;

  transition: 0.1s;
}

.Header_NavButton__3378V:hover {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.Header_NavButtonActive__2Wz9M {
  cursor: default;
  color: #000;
  background-color: #fff;
}

.Header_NavButtonActive__2Wz9M:hover {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.Header_MobileNavigation__3UQWd,
.Header_MobileNavigationHidden__3SUJE {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;

  z-index: 1000;

  padding: 10% 32px 0px 32px;

  background-color: #011b72;
}

.Header_MobileNavigation__3UQWd * {
  height: 50px;
  line-height: 45px;
  margin-bottom: 22px;
}

.Header_MobileNavigation__3UQWd img {
  width: 25px;
  margin: 0px auto;
  margin-top: 50px;
  height: auto;
}

.Header_MobileNavigation__3UQWd {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  pointer-events: all;
  opacity: 1;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}

.Header_MobileNavigationHidden__3SUJE {
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateY(-100px);
          transform: translateY(-100px);
}

@media only screen and (max-width: 1000px) {
  .Header_Right__W0Cje > * {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 878px) {
  .Header_MobileMenuButton__Uzygi {
    display: inline;
    display: initial;
  }
  .Header_Right__W0Cje .Header_NavButton__3378V {
    display: none;
  }
  .Header_ControlWrap__3yMrZ {
    padding: 0px 20px;
  }
}

.Footer_Wrap__18Y6G {
  min-height: 100px;

  padding: 20px;

  background-color: black;

  color: #fff;

  text-align: center;
}

.Footer_LangSelect__24S9x {
  text-align: center;
}

.Footer_LangItem__1QTy1 {
  height: 32px;

  margin: auto 20px;

  cursor: pointer;

  -webkit-transition: 0.1s;

  transition: 0.1s;
}

.Footer_LangItem__1QTy1:hover {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.Footer_Wrap__18Y6G p {
  margin-top: 50px;
}

.Footer_Wrap__18Y6G a {
  margin-left: 10px;
}

