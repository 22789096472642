.Wrap,
.WrapExpanded {
  height: 75px;

  color: #fff;
  background-color: #011b72;

  transition: 0.5s;

  overflow: hidden;
}

.WrapExpanded {
  height: 100vh;
}

.ControlWrap {
  height: 75px;
  max-width: 1300px;
  margin: 0px auto;
  display: flex;
}

.Left {
  display: flex;
}

.Left * {
  height: 22px;

  margin-top: auto;
  margin-bottom: auto;
}

.MobileMenuButton {
  display: none;
}

.MobileMenuButton img {
  height: 22px;
}

.Right {
  margin-right: 0px;
  margin-left: auto;
  display: flex;
}

.Right > * {
  margin-left: 50px;
  margin-top: auto;
  margin-bottom: auto;

  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

.NavButton {
  cursor: pointer;

  height: 30px;
  min-width: 150px;

  box-sizing: border-box;

  border-color: #fff;
  border-style: solid;
  border-width: 2px;
  border-radius: 30px;
  text-align: center;

  line-height: 150%;
  font-weight: 500;

  text-transform: uppercase;

  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */

  transition: 0.1s;
}

.NavButton:hover {
  transform: scale(0.95);
}

.NavButtonActive {
  cursor: default;
  color: #000;
  background-color: #fff;
}

.NavButtonActive:hover {
  transform: scale(1);
}

.MobileNavigation,
.MobileNavigationHidden {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  display: flex;
  flex-direction: column;

  z-index: 1000;

  padding: 10% 32px 0px 32px;

  background-color: #011b72;
}

.MobileNavigation * {
  height: 50px;
  line-height: 45px;
  margin-bottom: 22px;
}

.MobileNavigation img {
  width: 25px;
  margin: 0px auto;
  margin-top: 50px;
  height: auto;
}

.MobileNavigation {
  transform: translateY(0px);
  pointer-events: all;
  opacity: 1;
  transition: 0.1s;
}

.MobileNavigationHidden {
  opacity: 0;
  pointer-events: none;
  transform: translateY(-100px);
}

@media only screen and (max-width: 1000px) {
  .Right > * {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 878px) {
  .MobileMenuButton {
    display: initial;
  }
  .Right .NavButton {
    display: none;
  }
  .ControlWrap {
    padding: 0px 20px;
  }
}
