.Wrap {
  background-color: #011b72;
  min-height: 100vh;
  text-align: center;
}

.Head {
  padding: 50px 0px;
  text-align: center;
  color: #fff;
  font-size: 30px;
}

.TextNew {
  font-weight: 500;
}

.SubHead {
  display: inline-flex;
}

.Symbol {
  height: 50px;
  margin-right: 20px;
  margin-top: auto;
  margin-bottom: auto;
}

.TextName {
  text-align: left;
}

.Video {
  display: inline-block;
  width: 100%;
  max-width: 800px;

  background-color: #000;
}

.ButtonWrap {
  display: flex;
  text-align: center;
}

.Button {
  margin: 50px auto;
  text-align: center;
  padding: 10px 30px;
  background-color: #fff;
  border-radius: 100px;
  text-decoration: none;
  color: #011b72;

  min-width: 200px;

  font-weight: 600;
  transition: 0.1s;
}

.Button:hover {
  transform: scale(0.95);
}
