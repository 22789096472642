.Wrap {
}

.Head {
  display: flex;
}

.Title {
  display: flex;
  font-size: 30px;
}

.Title > img {
  height: 50px;
  margin-right: 20px;
  margin-top: auto;
  margin-bottom: auto;
}

.LangSelect {
  display: none;
  margin-right: 0px;
  margin-left: auto;

  display: flex;
}

.LangSelect > img {
  display: none;
  height: 32px;

  margin: auto 0px;
  margin-left: 18px;

  cursor: pointer;

  transition: 0.1s;
}

.LangSelect > img:hover {
  transform: scale(0.95);
}

.LangSelect > img:first-child {
  margin-left: 0px;
}

.Body {
  margin-top: 30px;
  margin-bottom: 30px;

  font-size: 18px;
  white-space: pre-wrap;
}

.Foot {
  display: flex;
}

.MediaCarousel {
  height: 100px;

  flex: 1;
  overflow-x: auto;
}

.MediaCarouselContainer {
  height: 100%;
  display: flex;
}

.MediaCarouselContainer > img {
  height: 100%;
  margin-right: 10px;
  cursor: pointer;
  transition: 0.1s;
}

.MediaCarouselContainer > img:hover {
  transform: scale(0.95);
}

.MediaCarouselContainer > img:last-child {
  margin-right: 0px;
}

.Redirect {
  margin: auto 0px;
  margin-left: 50px;
}

.Price {
  text-align: center;
  font-size: 16px;
  margin-bottom: 8px;
}

.Button {
  text-align: center;
  display: block;
  padding: 10px 30px;
  background-color: #e91e63;
  border-radius: 100px;
  text-decoration: none;
  color: #fff;

  transition: 0.1s;
}

.Button:hover {
  transform: scale(0.95);
}

.ArticleDivider {
  width: 100%;
  height: 2px;

  margin: 100px 0px;

  background-color: #e8e8e8;
}

@media only screen and (max-width: 600px) {
  .Head {
    display: inherit;
  }
  .Foot {
    display: inherit;
  }
  .Redirect {
    margin-top: 20px;
    margin-right: 50px;
  }
}
