.Wrap {
  text-align: center;
}

.Title {
  margin-bottom: 50px;
  font-size: 25px;
}

.ButtonGroup {
  display: inline-flex;
  text-align: center;
}

.Item {
  margin: 0px 16px;
}

.Button {
  display: inline-block;
  text-align: center;
  padding: 10px 30px;
  background-color: #ff0000;
  border-radius: 100px;
  text-decoration: none;
  color: #fff;

  transition: 0.1s;
}

.Button:hover {
  transform: scale(0.95);
}

@media only screen and (max-width: 700px) {
  .ButtonGroup {
    display: initial;
    text-align: center;
  }

  .Item {
    margin: 10px 16px;
  }
}
