.Wrap {
  max-width: 1300px;
  margin: 0px auto;

  animation: MoveIn 0.25s forwards;

  min-height: 100vh;

  padding: 50px 16px;
}

@keyframes MoveIn {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
